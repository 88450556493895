<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="saveSchool">
                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input
                        v-model="school.name"
                        placeholder=""
                        :state="validateState('name')"
                        aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio e deve contenere almeno 3 caratteri.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="businessName" label="Ragione Sociale" label-for="businessName" description="">
                    <b-form-input
                        v-model="school.businessName"
                        placeholder=""
                        :state="validateState('businessName')"
                        aria-describedby="businessName-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="businessName-live-feedback"
                    >Questo campo è obbligatorio e deve contenere la ragione sociale esatta.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="vat" label="Partita IVA" label-for="vat" description="">
                    <b-form-input
                        v-model="school.vat"
                        placeholder=""
                        :state="validateState('vat')"
                        aria-describedby="vat-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="vat-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="address" label="Indirizzo completo" label-for="address" description="">
                    <b-form-input v-model="school.address" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input
                        v-model="school.email"
                        placeholder=""
                        type="email"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="email-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>


                <b-form-group id="pec" label="Pec" label-for="pec" description="">
                    <b-form-input
                        v-model="school.pec"
                        placeholder=""
                        type="email"
                        :state="validateState('pec')"
                        aria-describedby="pec-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="pec-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="telephone" label="Telefono" label-for="telephone" description="">
                    <b-form-input
                        v-model="school.telephone"
                        placeholder=""
                        aria-describedby="telephone-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="telephone-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>


                Scuola principale: <toggle-button v-model="school.isMaster" :height="25"/>

                <br>
                <br>
                <b-form-group id="parentId" label="Seleziona la scuola principale a cui appartiene questa sede" label-for="parentId" description="" v-if="!school.isMaster">
                    <b-form-select v-model="school.parentId">
                        <option value="0">- Scegli una scuola -</option>
                        <option v-for="item in schools" v-bind:value="item.id" :key="item.id">
                            {{ item.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <br>
                <b-row>
                    <b-col sm="6">
                        <div style="background-color: #eeeeee; padding: 10px">
                            <upload-image v-on:uploaded-image="setLogo($event)" :label="'Logo'"/>
                        </div>
                    </b-col>
                </b-row>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="saveSchool" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import SchoolDataService from "./SchoolDataService";
import UploadImage from "@/components/upload/UploadImage";
import { required, minLength, maxLength, numeric, email } from 'vuelidate/lib/validators'

export default {
    name: "school-create",
    components: {UploadImage},
    data() {
        return {
            school: {
                id: null,
                name: "",
                businessName: "",
                vat: "",
                email: "",
                pec: "",
                telephone: "",
                isMaster: true,
                parentId: null,
                logo: null
            },
            schools: [],
            message: '',
            errorMsg: '',
            submitted: false
        };
    },
    validations: {
        school: {
            name: {
                required,
                minLength: minLength(4)
            },
            businessName: {
                required,
                minLength: minLength(4)
            },
            vat: {
                required,
                numeric,
                minLength: minLength(11),
                maxLength: maxLength(11),
            },
            email: {
                required,
                email
            },
            pec: {
                required,
                email
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.school[name];
            return $dirty ? !$error : null;
        },
        saveSchool() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                SchoolDataService.create(this.school)
                    .then(response => {
                        this.school.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-school');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
        setLogo(file) {
            this.school.logo = file.name;
        },
        newSchool() {
            this.submitted = false;
            this.school = {};
        }
    },
    mounted() {
        SchoolDataService.getAll()
            .then(response => {
                this.schools = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
