<template>
    <div v-if="currentSchool">
        <div class="card">
            <b-form @submit="updatePrograms">
                <div v-for="program in programs" :key="program.id" style="padding: 10px 0px; border-bottom: 1px solid #eee" >
                    <div class="row">
                        <div class="col-11">
                            <span class="label-title">{{ program.name }}</span>
                        </div>
                        <div class="col-1">
                            <b-checkbox v-model="program.selected"/>
                        </div>
                    </div>
                </div>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updatePrograms" :disabled="this.$store.state.loading">
                        <b-icon icon="file-earmark-check"/>
                        Salva
                    </button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade>
                    <b-icon icon="hand-thumbs-up"/>
                    {{ message }}
                </b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>
    </div>

    <div v-else>
        <br/>
        <p>Carico...</p>
    </div>
</template>

<script>
import SchoolDataService from "./SchoolDataService";
import ProgramDataService from "@/components/program/ProgramDataService";

export default {
    name: "program-school-edit",
    data() {
        return {
            programs: [],
            message: '',
            errorMsg: ''
        };
    },
    props: ["currentSchool"],
    methods: {

        updatePrograms() {
            SchoolDataService.updatePrograms(this.currentSchool.id, this.programs)
                .then(response => {
                    this.message = 'The school was updated successfully!';
                    this.$emit('updated-school', this.currentSchool);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        closeEditor() {
            this.$emit('closed-editor');
        },
    },
    mounted() {
        this.message = '';
        ProgramDataService.getAll()
            .then(response => {
                for (let program of response.data) {
                    program.selected = (this.currentSchool.programs.find(_item => _item.id === program.id) !== undefined);
                    this.programs.push(program)
                }
            })
            .catch(e => {
                console.log(e);
            });

    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
