<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Scuole</h3>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)" position="top"/>
            </div>

            <div class="col-md-3 text-right" style="">

            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentSchool = null">
                <b-icon icon="plus"/> Aggiungi Scuola
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40">#</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Proprietario</th>
                            <th scope="col">Scuola madre</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': school.id == currentId }"
                            v-for="school in schools"
                            :key="school.id"
                            @click="setActiveSchool(school)"
                        >
                            <td>{{ school.id }}</td>
                            <td>{{ school.name }}</td>
                            <td>
                                <span v-if="!hasOwner(school)" class="text-danger"><b-icon icon="exclamation-triangle-fill"/> Manca il proprietario</span>
                                <span v-else>{{ school.profiles[0].firstname }} {{ school.profiles[0].lastname }}</span>
                            </td>
                            <td><span v-if="!school.isMaster">{{ printParent(school) }}</span></td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.schools.length == 0">Ancora nessuna scuola presente</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0), 'editor-full': (currentId > 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <school-edit :school-id="currentSchool.id" :key="currentSchool.id"
                                  v-on:updated-school="refreshCurrentSchool($event)"
                                  v-on:deleted-school="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <school-create
                        v-on:created-school="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import SchoolDataService from "./SchoolDataService";
import SchoolEdit from "@/components/school/SchoolEdit";
import SchoolCreate from "@/components/school/SchoolCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "schools-index",
    components: {SchoolEdit, SchoolCreate, SearchBar},
    data() {
        return {
            schools: [],
            currentSchool: null,
            currentId: -1,
            user: {}
        };
    },
    methods: {
        retrieveSchools() {
            SchoolDataService.getAllWithOwners()
                .then(response => {
                    this.schools = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveSchools();
            this.currentSchool = null;
            this.currentId = -1;
        },

        refreshCurrentSchool(school) {
            this.schools.forEach(function (item) {
                if (item.id == school.id) {
                    item.name = school.name;
                    item.parentId = school.parentId;
                    item.profiles = school.profiles;
                }
            });
            this.closeEditor();
            //this.currentSchool = school;
            //this.currentId = school.id;
        },

        setActiveSchool(school) {
            this.currentSchool = school;
            this.currentId = school.id;
        },

        searchName(name) {
            this.currentSchool = null;
            this.currentId = -1;
            SchoolDataService.findByName(name)
                .then(response => {
                    this.schools = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        hasOwner(school) {
            return (typeof school.profiles[0] != "undefined");
        },

        printParent(school) {
            const parent = this.schools.find(s => s.id == school.parentId);
            return parent.name;
        },

        closeEditor() {
            this.currentSchool = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveSchools();
        this.user = this.$store.state.user;
    }
};
</script>

<style scoped>
.editor-full {
    /*max-width: 1280px;*/
}
</style>
