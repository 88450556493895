<template>
    <div v-if="currentOwner">
        <div class="card">
            <b-form @submit="updateOwner">

                <b-form-group id="firstname" label="Nome" label-for="firstname" description="">
                    <b-form-input v-model="currentOwner.firstname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="lastname" label="Cognome" label-for="lastname" description="">
                    <b-form-input v-model="currentOwner.lastname" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input v-model="currentOwner.email" placeholder="" type="email" disabled></b-form-input>
                </b-form-group>

                <b-form-group id="password" label="Password" label-for="password" description="Compila il campo solo se vuoi cambiare la password dell'utente, oppure lascialo vuoto.">
                    <b-form-input v-model="currentOwner.password" placeholder="" type="password"></b-form-input>
                </b-form-group>

                Attivo: <toggle-button v-model="currentOwner.status" :height="25"/>
            </b-form>

            <p class="alert alert-primary" role="alert">Attenzione, per cambiare il proprietario di questa scuola è necessario prima rimuovere l'attuale proprietario e poi aggiungerne uno nuovo.</p>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateOwner" :disabled="this.$store.state.loading">
                        <b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteOwner" :disabled="this.$store.state.loading"> <b-icon icon="trash"/> Rimuovi</b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>


    </div>

    <div v-else>
        <br/>
        <p>{{ (errorMsg) ? errorMsg : 'Carico...' }}</p>
    </div>
</template>

<script>
import OwnerDataService from "@/components/owner/OwnerDataService";

export default {
    name: "owner-edit",
    data() {
        return {
            currentOwner: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["ownerId", 'schoolId'],
    methods: {
        getStudent(id) {
            OwnerDataService.get(id)
                .then(response => {
                    this.currentOwner = response.data;
                    this.currentOwner.email = response.data.user.email;
                    this.currentOwner.status = (this.currentOwner.status == 1);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },
        validate(name) {
            return this.currentOwner[name] != '';
        },
        updateOwner() {
            OwnerDataService.update(this.currentOwner.id, this.currentOwner)
                .then(response => {
                    this.message = 'Informazioni salvate con successo!';
                    this.$emit('updated-owner', this.currentOwner);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteOwner() {
            if(confirm(`Vuoi rimuovere il proprietario ${this.currentOwner.firstname} ${this.currentOwner.lastname}? Il profilo non verrà eliminato.`)) {
                OwnerDataService.delete(this.currentOwner.id, this.schoolId)
                    .then(response => {
                        this.$emit('deleted-owner');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getStudent(this.ownerId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
