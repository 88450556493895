import http from "../../helpers/http-common";

class OwnerDataService {
    getAll() {
        return http.get("/owners");
    }

    get(id) {
        return http.get(`/owners/${id}`);
    }

    create(data) {
        return http.post("/owners", data);
    }

    update(id, data) {
        return http.put(`/owners/${id}`, data);
    }

    delete(id, schoolId) {
        return http.delete(`/owners/${id}/${schoolId}`);
    }

    deleteAll() {
        return http.delete('/owners');
    }

    findByName(name) {
        return http.get(`/owners?name=${name}`);
    }
}

export default new OwnerDataService();
