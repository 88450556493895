<template>
    <div v-if="currentSchool">

        <div class="card" v-if="hasRole('admin') && hasOwner()">
            <br>
            <b-row>
                <b-col sm="8">
                    <h4>Entra in modalita proprietario</h4>
                </b-col>
                <b-col sm="4">
                    <b-button variant="warning" block @click="enterSchool"><b-icon icon="box-arrow-in-right"/> Entra</b-button>
                </b-col>
            </b-row>
        </div>
        <div class="card" v-if="hasRole('admin') && !hasOwner()">

            <owner-create :school-id="schoolId"
                          v-on:created-owner="createdOwner(schoolId)"
                          v-on:closed-editor="closeEditor()"/>
        </div>
        <br>

        <div class="container">
            <h4>Dati della scuola</h4>
        </div>
        <div class="card">
            <b-form @submit="updateSchool">

                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentSchool.name" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="businessName" label="Ragione Sociale" label-for="businessName" description="">
                    <b-form-input v-model="currentSchool.businessName" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="address" label="Indirizzo completo" label-for="address" description="">
                    <b-form-input v-model="currentSchool.address" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="vat" label="Partita IVA" label-for="vat" description="">
                    <b-form-input v-model="currentSchool.vat" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="email" label="Email" label-for="email" description="">
                    <b-form-input v-model="currentSchool.email" placeholder="" type="email"></b-form-input>
                </b-form-group>

                <b-form-group id="pec" label="Pec" label-for="pec" description="">
                    <b-form-input v-model="currentSchool.pec" placeholder="" type="email"></b-form-input>
                </b-form-group>

                <b-form-group id="telephone" label="Telefono" label-for="telephone" description="">
                    <b-form-input v-model="currentSchool.telephone" placeholder=""></b-form-input>
                </b-form-group>

                Scuola principale:
                <toggle-button v-model="currentSchool.isMaster" :height="25"/>


                <br>
                <br>
                <b-form-group id="parentId" label="Seleziona la scuola principale a cui appartiene questa sede" label-for="parentId" description="" v-if="!currentSchool.isMaster">
                    <b-form-select v-model="currentSchool.parentId">
                        <option value="0">- Scegli una scuola -</option>
                        <option v-for="item in schools" v-bind:value="item.id" :key="item.id">
                            {{ item.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <br>
                <b-row>
                    <b-col sm="6">
                        <div style="background-color: #eeeeee; padding: 10px">
                            <upload-image v-on:uploaded-image="setLogo($event)" :current-image="currentLogo" :label="'Logo'"/>
                        </div>
                    </b-col>
                </b-row>

                <br>
                <b-row style="background-color: #eeeeee; padding: 10px">
                    <b-col sm="12"><b>Intestazione per le tessere</b></b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setFront($event)" :current-image="currentFrontImage" :label="'FRONTE'"/>
                    </b-col>
                    <b-col sm="6">
                        <upload-image v-on:uploaded-image="setRetro($event)" :current-image="currentRetroImage" :label="'RETRO'"/>
                    </b-col>
                </b-row>

                <!-- SEZIONE VENDITA GUIDE -->
                <hr>
                <h4>Configurazione per la vendita delle guide</h4>

                <p><a href="./Istruzioni EduGest PayPal.pdf" target="_blank">Leggi la guida per la configurazione <b-icon-box-arrow-up-right/></a></p>

                <h6>PAYPAL</h6>

                <b-form-group id="paypalClientId" label="PayPal Client ID" label-for="paypalClientId" description="">
                    <b-form-input v-model="currentSchool.paypalClientId" placeholder=""></b-form-input>
                </b-form-group>

                <b-form-group id="paypalClientSecret" label="PayPal Client Secret" label-for="paypalClientSecret" description="">
                    <b-form-input v-model="currentSchool.paypalClientSecret" placeholder=""></b-form-input>
                </b-form-group>

                <h6>PREZZI</h6>
                <b-row>
                    <b-col sm="6">
                        <b-form-group id="practicePriceHour" label="Prezzo ora guida reale" label-for="practicePriceHour" description="">
                            <b-form-input v-model="currentSchool.practicePriceHour" placeholder=""></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group id="simulatorPriceHour" label="Prezzo ora simulatore" label-for="simulatorPriceHour" description="">
                            <b-form-input v-model="currentSchool.simulatorPriceHour" placeholder=""></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>


            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateSchool" :disabled="this.$store.state.loading">
                        <b-icon icon="file-earmark-check"/>
                        Salva
                    </button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="deleteSchool" :disabled="this.$store.state.loading">
                        <b-icon icon="trash"/>
                    </b-button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade>
                    <b-icon icon="hand-thumbs-up"/>
                    {{ message }}
                </b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <!-- SEZIONE PROPRIETARIO -->
        <br>
        <div v-if="hasRole('admin') && this.currentSchool">
            <div v-if="hasOwner()">
                <div class="container">
                    <h4>Dati del proprietario</h4>
                </div>

                <owner-edit v-if="currentSchool"
                            :owner-id="currentSchool.profiles[0].id"
                            :school-id="schoolId"
                            :key="currentSchool.profiles[0].id"
                            v-on:deleted-owner="createdOwner(schoolId)"
                            v-on:closed-editor="closeEditor()"/>
            </div>

        </div>


        <!-- SEZIONE PROGRAMMI DISPONIBILI-->
        <br>
        <div class="container">
            <h4>Programmi disponibili</h4>
        </div>
        <program-school-edit :current-school="currentSchool"
                             v-on:closed-editor="closeEditor()" />

    </div>

    <div v-else>
        <br/>
        <p>Carico...</p>
    </div>
</template>

<script>
import SchoolDataService from "./SchoolDataService";
import OwnerEdit from "@/components/owner/OwnerEdit";
import OwnerCreate from "@/components/owner/OwnerCreate";
import ProgramDataService from "@/components/program/ProgramDataService";
import ProgramSchoolEdit from "@/components/school/ProgramSchoolEdit";
import UploadImage from "@/components/upload/UploadImage";
import UploadDataService from "@/components/upload/UploadDataService";
import {store} from "@/components/store";

export default {
    name: "school-edit",
    components: {OwnerCreate, OwnerEdit, ProgramSchoolEdit, UploadImage},
    data() {
        return {
            currentSchool: null,
            currentLogo: null,
            currentFrontImage: null,
            currentRetroImage: null,
            programs: [],
            schools: [],
            message: '',
            errorMsg: ''
        };
    },
    props: ["schoolId"],
    methods: {
        getSchool(id, emitEvent = false) {
            SchoolDataService.get(id)
                .then(response => {
                    this.currentSchool = response.data;
                    this.currentSchool.isMaster = (this.currentSchool.isMaster === 1);
                    if (emitEvent) {
                        this.$emit('updated-school', this.currentSchool);
                    }
                    if (this.currentSchool.logo) UploadDataService.get(this.currentSchool.logo)
                        .then(response => {
                            this.currentLogo = response.data.encoded;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });

                    if (this.currentSchool.cardHeaderFront) UploadDataService.get(this.currentSchool.cardHeaderFront)
                        .then(response => {
                            this.currentFrontImage = response.data.encoded;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });

                    if (this.currentSchool.cardHeaderRetro) UploadDataService.get(this.currentSchool.cardHeaderRetro)
                        .then(response => {
                            this.currentRetroImage = response.data.encoded;
                        })
                        .catch(e => {
                            this.errorMsg = e;
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        },

        updateSchool() {
            SchoolDataService.update(this.currentSchool.id, this.currentSchool)
                .then(response => {
                    this.message = 'The school was updated successfully!';
                    this.$emit('updated-school', this.currentSchool);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        deleteSchool() {
            if (confirm(`Vuoi cancellare la scuola ${this.currentSchool.name}?`)) {
                SchoolDataService.delete(this.currentSchool.id)
                    .then(response => {
                        this.$emit('deleted-school');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        },

        enterSchool() {
            let authUser = JSON.parse(window.localStorage.getItem('authUser'));
            authUser.data.schoolId = this.schoolId;
            window.localStorage.setItem('authUser', JSON.stringify(authUser));
            this.$store.state.schoolId = this.schoolId;
            this.$store.state.schoolName = this.currentSchool.name;
            this.$router.push('school')
        },
        setLogo(file) {
            this.currentSchool.logo = file.name;
        },
        hasOwner() {
            return (typeof this.currentSchool.profiles[0] != "undefined" && !this.currentSchool.profiles[0].deleted);
        },
        createdOwner(schoolId) {
            this.getSchool(schoolId, true);
        },
        setFront(file) {
            this.currentSchool.cardHeaderFront = file.name;
        },
        setRetro(file) {
            this.currentSchool.cardHeaderRetro = file.name;
        },
    },
    mounted() {
        this.message = '';
        this.getSchool(this.schoolId);
        ProgramDataService.getAll()
            .then(response => {
                this.programs = response.data
            })
            .catch(e => {
                console.log(e);
            });
        SchoolDataService.getAll()
            .then(response => {
                this.schools = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
